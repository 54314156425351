<template>
    <div>
        <div v-if="loading" class="loading-overlay">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading... <br> If there are many users, the execution will take longer,
                    5-10 minutes.</span>
            </div>
        </div>
        <form @submit.prevent="handleSubmit" enctype="multipart/form-data" :class="{ 'loading-form': loading }">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="Title" name='Title' v-model="Title"
                    placeholder="@example.com">
                <label for="Title">{{ $t('Title') }}</label>
            </div>

            <div class="input-group mb-3">
                <label class="input-group-text" for="Image">{{ $t('Image') }}</label>
                <input type="file" class="form-control" ref="fileInput" @change="handleFileUpload" id="Image"
                    name="Image" accept="image/png, image/jpeg, image/jpg, image/gif">
            </div>

            <div class="form-floating mb-3">
                <textarea class="form-control" placeholder="Leave a comment here" v-model="Message" id="Message"
                    name="Message" style="height: 100px"></textarea>
                <label for="Message">{{ $t('Message') }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="Schedule" name="Schedule" id="Now" value="Now"
                    checked>
                <label class="form-check-label" for="Now">{{ $t('Now') }}</label>
            </div>
            <br>
            * 暂时只能使用即时（Currently only available for Now）
            <!-- <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="Schedule" name="Schedule" id="Day" value="Day">
                <label class="form-check-label user-select-none" for="Day">{{ $t('Day') }}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="Schedule" name="Schedule" id="Week" value="Week">
                <label class="form-check-label user-select-none" for="Week">{{ $t('Week') }}</label>
            </div> -->
            <div v-if="Schedule === 'Week'">
                <div class="form-check form-check-inline" v-for="day in Weeklys" :key="day[0]">
                    <input class="form-check-input" type="checkbox" :id="day[0]" :value="day[0]"
                        v-model="selectedDays[day[0]]">
                    <label class="form-check-label user-select-none" :for="day[0]">{{ day[1] }}</label>
                </div>
            </div>
            <div v-if="Schedule === 'Week' || Schedule === 'Day'">
                <input type="range" class="form-range" v-model="Time" min="0" max="24">
                <p class="text-start">{{ $t('Time') }}：{{ convertedTime }}</p>
            </div>
            <div class="btn-group d-flex justify-content-center">
                <input type="submit" class="btn btn-outline-success" name="DataSumbit" value="確認">
            </div>
        </form>

    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            Weeklys: [
                ['Sunday', this.$t("Sunday")],
                ['Monday', this.$t("Monday")],
                ['Tuesday', this.$t("Tuesday")],
                ['Wednesday', this.$t("Wednesday")],
                ['Thursday', this.$t("Thursday")],
                ['Friday', this.$t("Friday")],
                ['Saturday', this.$t("Saturday")]],
            Schedule: 'Now',
            Time: 0,
            Image: null,
            loading: false,
        };
    },
    created() {
        // 将Weeklys数组转换为selectedDays对象
        this.selectedDays = this.Weeklys.reduce((acc, [day]) => {
            acc[day] = false;
            return acc;
        }, {});
        // this.$root.$on('send-message', this.handleMessage);

    },
    computed: {
        convertedTime() {
            let hours = this.Time % 12;
            hours = hours === 0 ? 12 : hours; // 如果小时为0，显示12
            const period = this.Time >= 12 ? 'PM' : 'AM';
            return `${hours}:00 ${period}`;
        }
    },
    methods: {

        handleMessage(data) {
            // 处理接收到的数据
            alert(data);
            // this.receivedData = data;
            // 在这里设置SendMessage.vue的data属性
        },
        handleFileUpload(event) {
            // 获取文件对象
            const file = event.target.files[0];
            // 将文件对象存储在 Vue 实例的 data 属性中
            this.Image = file;
        },

        handleSubmit() {
            this.loading = true;


            // 构建一个 FormData 对象
            const formData = new FormData();
            formData.append('Title', this.Title); // Assuming this.Title is bound to your input field
            formData.append('Image', this.Image); // Assuming this.Image is bound to your file input field
            formData.append('Message', this.Message); // Assuming this.Message is bound to your textarea input field
            formData.append('Schedule', this.Schedule);
            const selectedDaysArray = Object.entries(this.selectedDays);
            selectedDaysArray.forEach(([day, isChecked]) => {
                if (isChecked) {
                    formData.append('Week[]', day);
                }
            });
            formData.append('Time', this.Time);

            axios.post('https://ms.app96.cc/VueAPI/MessageSend.php?AppID=' + localStorage.getItem('AppID'), formData)
                .then(response => {
                    // 处理响应
                    console.log(response);
                    this.SendOK()
                })
                .catch(error => {
                    // 处理错误
                    console.log(error);
                });
        },
        SendOK() {
            this.loading = false;
            this.Title = "";
            this.Message = "";
            this.Image = null;
            // document.getElementById("Title").value = "";
            // document.getElementById("Message").value = "";
            // document.getElementById("Image").value = "";
            // 获取第一个拥有类名为"btn-close"的元素
            var closeButton = document.querySelector(".btn-close");
            // 创建一个点击事件
            var clickEvent = new Event("click");
            // 分派点击事件到按钮元素
            closeButton.dispatchEvent(clickEvent);
        }

    },


}
</script>


<style scoped>
/* 添加一个样式用于禁用表单 */
.loading-form input:disabled {
    background-color: rgba(255, 255, 255, 0.5);
    /* 这里的 0.5 控制表单的透明度，可以根据需要调整 */
}

/* 显示加载动画的覆盖层 */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    /* 这里的 0.8 控制覆盖层的透明度，可以根据需要调整 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* 确保覆盖在其他元素之上 */
}
</style>