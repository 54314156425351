<template>
    <div class="container" style="margin-top:10px;">
        <div class="card text-bg-secondary">
            <div class="card-header">
                Contact Seach
            </div>
            <div class="card-body">
                <div class="input-group mb-3">
                    <input type="month" v-model="month" class="form-control">
                    <button class="btn btn-primary" type="button" @click="fetchData('ct')">NewSeach</button>
                    <button class="btn btn-primary" type="button" @click="fetchData('bo')">OldSeach</button>
                </div>

            </div>
        </div>
        <br>
        <div class="card border-success">
            <div class="card-header">
                Contact
            </div>
            <div class="card-body">
                <p v-if="!loading && !exporting">
                    <vxe-input v-model="filterName" type="search" :placeholder="$t('Search')"></vxe-input>
                </p>
                <div class="container" style="margin-top:10px;">
                    <!-- Loading indicator -->
                    <div v-if="loading" class="text-primary" disabled>
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </div>
                    <div v-if="!loading">
                        <vxe-table show-overflow max-height="500" ref="tableRef" :data="filteredItems">
                            <vxe-column type="seq" title="#"></vxe-column>
                            <vxe-column field="ID" title="No." sortable></vxe-column>
                            <vxe-column :title="$t('Download')">
                                <template #default="{ row }">
                                    <vxe-button @click="UpdateMessage(row.ID)">{{ $t('Download') }}</vxe-button>
                                    <vxe-button @click="DeleteMessage(row.ID)">{{ $t('Delete') }}</vxe-button>
                                </template>
                            </vxe-column>
                        </vxe-table>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios';
// import ContactFull from "@/views/ContactFull.vue";
import {
    ref,
    onMounted,
    watch
} from 'vue';

export default {

    setup() {

        const Count = ref(0);

        const filterName = ref('');

        function UpdateMessage(i) {
            let apiUrl;

            apiUrl = `https://${SQLData.value}.app96.cc/VueAPI/ContactNotedCSV.php?AppID=${localStorage.getItem('AppID')}&ID=${i}`;
            window.open(apiUrl, '_blank');
            // Count.value = i;
        }

        function DeleteMessage(i) {
            // eslint-disable-next-line
            Swal.fire({
                title: 'Are You Sure Detele this Contact?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        const apiUrl = `https://${SQLData.value}.app96.cc/VueAPI/ContactNotedDelete.php?AppID=${localStorage.getItem('AppID')}&ID=${i}`;

                        loading.value = true;
                        axios.get(apiUrl);
                    } catch (error) {
                        console.error('Error fetching data: ', error);
                    } finally {
                        const index = this.Items.findIndex(item => item.ID === i);
                        if (index !== -1) {
                            this.Items.splice(index, 1);
                        }
                        // eslint-disable-next-line
                        Swal.fire(
                            'Delete!',
                            'this is delete',
                            'success'
                        );
                        loading.value = false;
                    }
                }
            });
        }

        // provide("ContactID", Count)

        const Items = ref([]);
        const loading = ref(false);
        const month = ref(new Date().toISOString().slice(0, 7));
        const filteredItems = ref([]);
        const tableRef = ref();
        const SQLData = ref('bo');
        const fetchData = async (u) => {
            try {
                SQLData.value = u;
                const apiUrl = 'https://' + u + '.app96.cc/VueAPI/ContactNotedMonth.php?AppID=' + localStorage.getItem('AppID') + '&month=' + month.value;

                loading.value = true;
                // const response = await axios.post(apiUrl, {
                //     month: this.month
                // });
                const response = await axios.get(apiUrl);
                Items.value = response.data;
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                loading.value = false;
                filterName.value = '';
            }
        };
        onMounted(() => {
            // fetchData();
        });
        watch(() => {
            // 观察 filterName 的变化，并根据新的值过滤 Items
            filteredItems.value = Items.value.filter((item) =>
                Object.values(item).some((value) =>
                    String(value).toLowerCase().includes(filterName.value.toLowerCase())
                )
            );
        });
        // const exportDataEvent = async () => {
        //     // exporting.value = true; // Set the exporting flag to true
        //     let apiUrl;

        //     apiUrl = `https://bo.app96.cc/VueAPI/ContactsCSV.php?AppID=${localStorage.getItem('AppID')}&ID=${ContactID.value}`;
        //     window.open(apiUrl, '_blank');
        // };
        return {
            Count,
            filterName,
            Items,
            loading,
            month,
            tableRef,
            filteredItems,
            SQLData,
            fetchData,
            // exportDataEvent,
            UpdateMessage,
            DeleteMessage
        }
    },
    // components: {
    //     ContactFull
    // },
};
</script>
