<template>
    <div>

        <div class="my-3 p-3 bg-body rounded shadow-sm">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center nav-tabs">
                <div role="tablist">
                    <h6 class="border-bottom pb-2 mb-0">{{ this.$t("TimingOperation") }}</h6>
                </div>
                <div>
                    <i class="fa-solid fa-arrows-rotate" role="button" @click="callMessage"></i>

                </div>
            </div>


            <div v-for="item in Items" :key="item.ID">
                <div class="d-flex text-muted pt-3 border-bottom">
                    <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32"
                        xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32"
                        preserveAspectRatio="xMidYMid slice" focusable="false">

                        <rect width="100%" height="100%" v-bind:fill="item.Color" /><text x="50%" y="50%"
                            v-bind:fill="item.Color" dy=".3em">32x32</text>
                    </svg>
                    <div class="pb-3 mb-0 small lh-sm w-100">
                        <strong class="d-block text-gray-dark">{{ item.Title }}</strong>
                        <img v-if="item.Image !== null" :src="item.Image" class="rounded mx-auto d-block w-50">
                        <div class="mt-2 mb-3">{{ item.Message }}</div>
                        <div class="hstack gap-3">
                            <!-- <div class="p-2">First item</div> -->
                            <div class="p-2 ms-auto">
                                <Popper arrow>
                                    <template v-slot:content>
                                        <div v-html="formattedContent(item.Count, item.Schedule, item.Week, item.Time)">
                                        </div>
                                    </template>
                                    <i class="fa-solid fa-hashtag" role="button"> {{ this.$t("Information") }}</i>
                                </Popper>
                            </div>
                            <div class="vr"></div>
                            <div class="p-2">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        :id="'checkbox-' + item.ID" v-model="item.State" @change="toggleFunction(item)">
                                    <label class="form-check-label" :for="'checkbox-' + item.ID" v-if="item.State">{{
                                        this.$t("On") }}</label>
                                    <label class="form-check-label" :for="'checkbox-' + item.ID" v-else>{{ this.$t("Off")
                                    }}</label>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios';
import Popper from "vue3-popper";

export default {

    components: {
        Popper,
    },
    computed: {

    },
    data() {
        return {
            Items: [],
        };
    },
    created() {
        this.callMessage()
    },
    methods: {
        toggleFunction(item) {
            const formData = new FormData();
            formData.append('State', item.State);
            formData.append('MessageTimeID', item.ID); // Assuming this.Title is bound to your input field
            axios.post('https://bo.app96.cc/VueAPI/MessageSettingState.php?AppID=' + localStorage.getItem('AppID'), formData)
                .then(response => {
                    // 处理响应
                    console.log(response);
                    alert(response);

                    // this.SendOK()
                })
                .catch(error => {
                    // 处理错误
                    console.log(error);
                });

        },
        formattedContent(Count, Schedule, Weekly, Time) {

            let scheduleText = Schedule === 'Day' ? this.$t("Day") : this.$t("Week");

            let WeeklyText = Schedule === 'Day' ? '' : "<br>" + Weekly + "<br>";
            return `${this.$t("Implement")}：${Count}<br>${this.$t("Time")}：${scheduleText} ${WeeklyText}${this.convertedTime(Time)}<br>`;
        },
        convertedTime(Time) {
            let hours = Time % 12;
            hours = hours === 0 ? 12 : hours; // 如果小时为0，显示12
            const period = Time >= 12 ? 'PM' : 'AM';
            return `${hours}:00 ${period}`;
        },
        callMessage() {
            axios.get('https://bo.app96.cc/VueAPI/MessageTime.php?AppID=' + localStorage.getItem('AppID'))
                .then(response => {
                    this.Items = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                })
        },
    }
};

</script>

<style scoped>
:deep(.popper) {
    background: #7c4c66 !important;
    padding: 20px !important;
    border-radius: 20px !important;
    color: #fff !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
}

:deep(.popper #arrow::before) {
    background: #165109;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    background: #060104;
}
</style>