<template>
    <div class="container" style="margin-top:10px;">

        <p class="fs-2">{{ $t('ChangePassword') }}</p>
        <form @submit.prevent="handleSubmit" enctype="multipart/form-data" :class="{ 'loading-form': loading }">
            <div class="form-floating mb-3">
                <input type="password" class="form-control" v-model="OldPassword" placeholder="name@example.com">
                <label for="floatingInput">Old Password</label>
            </div>
            <div class="form-floating">
                <input type="password" class="form-control" v-model="NewPassword" placeholder="Password">
                <label for="floatingPassword">New Password</label>
            </div>
            <div class="btn-group d-flex justify-content-center mt-4">
                <input type="submit" class="btn btn-outline-success" value="submit">
            </div>
        </form>
    </div>
</template>
<script>

import axios from 'axios';
export default {
    data() {
        return {
            OldPassword: null,
            NewPassword: null
        };
    },
    methods: {


        handleSubmit() {
            // 构建一个 FormData 对象
            const formData = new FormData();
            formData.append('OldPassword', this.OldPassword);
            formData.append('NewPassword', this.NewPassword);


            // 在这里处理表单提交逻辑

            // 使用axios或者fetch API提交表单数据到服务端
            // 例如，使用axios：
            axios.post('https://bo.app96.cc/VueAPI/ChangePassword.php?AppID=' + localStorage.getItem('AppID'), formData)
                .then(response => {
                    // 处理响应
                    if (!response.data.error) {
                        this.$router.push("/");
                    } else {
                        alert(response.data.message);
                    }
                });
        },
    }
};
</script>