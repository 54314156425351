<template>
    <div class="container" style="margin-top:10px;">

        <!-- <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center nav-tabs">
            <div class="nav" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history"
                    type="button" role="tab" aria-controls="nav-history" aria-selected="true">{{ $t('All') }}</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{{ $t('Class')
                    }}</button>
            </div>
        </div> -->

        <!-- <div class="tab-content" id="nav-tabContent"> -->
        <!-- <div class="tab-pane fade show active" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab"
                tabindex="0">
                <ContactFull />

            </div> -->
        <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
            tabindex="0">
            <ContactNoted />
        </div>
        <!-- </div> -->
    </div>
</template>
<script>


// import ContactFull from "@/views/ContactFull.vue";
import ContactNoted from "@/views/ContactNoted.vue";

export default {
    components: {
        // ContactFull,
        ContactNoted
    },
};
</script>