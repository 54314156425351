<template>
    <div>

        <section class="vh-100">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6 text-black">

                        <div class="px-5 ms-xl-4 mt-5">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="https://bo.app96.cc/Icon/Logo.png" style="width:5em;">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="h1 fw-bold mb-0">App96.cc</span>
                                </div>
                            </div>

                        </div>

                        <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
                            <form @submit.prevent="login" enctype="multipart/form-data" style="width: 23rem;">

                                <h3 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Log in</h3>
                                <div class="form-floating mb-4">
                                    <input type="text" id="Account" name="Account" v-model="Account"
                                        class="form-control form-control-lg" placeholder="" required />
                                    <label class="form-label" for="Account">Account</label>
                                </div>

                                <div class="form-floating mb-4">
                                    <input type="password" id="Password" name="Password" v-model="Password"
                                        class="form-control form-control-lg" placeholder="Password" required />
                                    <label class="form-label" for="Password">Password</label>
                                </div>
                                <div class="d-grid gap-2 mb-5">
                                    <button type="submit" class="btn btn-info btn-lg btn-block" name="Login">Login</button>
                                </div>

                                <p>{{ errorText }}</p>

                            </form>

                        </div>

                    </div>
                    <div class="col-sm-6 px-0 d-none d-sm-block">
                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img3.webp"
                            alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
  
<script>
// 假设您使用 axios 发送登录请求
import axios from 'axios';

export default {
    date() {
        return {
            errorText: ""
        }
    },
    methods: {
        login() {

            // 构建一个 FormData 对象
            const formData = new FormData();
            formData.append('Account', this.Account);
            formData.append('Password', this.Password);
            axios.post('https://bo.app96.cc/VueAPI/Login.php', formData)
                .then(response => {
                    // 处理响应
                    console.log(response);
                    if (response.data != "帳號或密碼錯誤") {
                        // alert(response.data);
                        localStorage.setItem('AppID', response.data);
                        window.location.reload();
                    } else {
                        alert(response.data);
                    }
                })
                .catch(error => {
                    // 处理错误
                    console.log(error);
                });
        }
    }

};

</script>
  