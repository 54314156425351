import { createRouter, createWebHistory } from 'vue-router';
import AppHome from '@/views/AppHome.vue';
import AppContact from '@/views/AppContact.vue';
import AppMessage from '@/views/AppMessage.vue';
import ChangePassword from '@/views/AppChangePassword.vue';

const routes = [
    { path: '/', component: AppHome },
    { path: '/Contact', component: AppContact },
    { path: '/Message', component: AppMessage },
    { path: '/CP', component: ChangePassword }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
