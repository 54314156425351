// i18n.js
import { createI18n } from 'vue-i18n';

// Function to determine the initial locale
function getInitialLocale() {
    // You can implement your logic here to determine the initial locale
    // For example, check a user preference, browser settings, etc.
    const userLocalePreference = localStorage.getItem('userLocale');
    return userLocalePreference || navigator.language || 'en';
}
const i18n = createI18n({
    legacy: false, // 使用 composition API
    locale: getInitialLocale(),
    messages: {
        en: {
            hello: 'Hello, world!!!!!',
            SendMessage: 'Send Message',
            Home: 'Home',
            Contact: 'Contact',
            Message: 'Message',
            All: 'All',
            Class: 'Class',
            History: 'History',
            Send: 'Send',
            Time: 'Time',
            Title: 'Title',
            Image: 'Image',
            Now: 'Now',
            Day: 'Day',
            Sunday: 'Sunday',
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            TimingOperation: 'Timing Operation',
            Information: 'Information',
            On: 'On',
            Off: 'Off',
            Implement: 'Implement',
            More: 'More',
            Record: 'Record',
            Timing: 'Timing',
            Search: 'Search',
            Export: 'Export',
            Name: 'Name',
            Number: 'Number',
            Email: 'Email',
            Check: 'Check',
            ChangePassword: 'Change Password',
            Download: 'Download',
            Delete: 'Delete',
            yes: 'yes',
            cancel: 'cancel',
        },
        zh: {
            hello: '你好，世界！',
            SendMessage: '發送消息',
            Home: '首页',
            Contact: '通訊錄',
            Message: '消息',
            All: '所有',
            Class: '分類',
            History: '歷史記錄',
            Send: '發送',
            Time: '時間',
            Title: '標題',
            Image: '圖片',
            Now: '即時',
            Day: '每天',
            Week: '每週',
            Sunday: '星期日',
            Monday: '星期一',
            Tuesday: '星期二',
            Wednesday: '星期三',
            Thursday: '星期四',
            Friday: '星期五',
            Saturday: '星期六',
            TimingOperation: '定時操作',
            Information: '資訊',
            On: '啟用',
            Off: '關閉',
            Implement: '執行',
            More: '更多',
            Record: '記錄',
            Timing: '定時',
            Search: '搜尋',
            Export: '導出',
            Name: '姓名',
            Number: '號碼',
            Email: '郵箱',
            Check: '查看',
            ChangePassword: '更換密碼',
            Download: '下載',
            Delete: '刪除',
            yes: '確定',
            cancel: '取消',
        },
    },
});

export default i18n;

