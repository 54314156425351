// store.js
import { createStore } from 'vuex';

export default createStore({
    state: {
        // ... 其他状态
    },
    mutations: {
        // ... 其他 mutations
        SET_LANGUAGE(state, newLocale) {
            state.locale = newLocale;
        },
    },
    actions: {
        // ... 其他 actions
        changeLanguage({ commit }, newLocale) {
            // Additional logic if needed
            localStorage.setItem('userLocale', newLocale);
            commit('SET_LANGUAGE', newLocale);
            location.reload();
        },
    },
});
