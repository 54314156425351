<template>
    <div class="sidebar" id="sidebar">

        <ul>
            <li class="logo">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <router-link to="/">
                        <span class="icon">
                            <img src="https://bo.app96.cc/Icon/Logo.png" style="width:1.5em;">
                        </span>
                        <span class="text">App96.cc</span>
                    </router-link>
                    <span class="icon-back" @click="toggleMenu()">
                        <i class="fa-solid fa-bars" v-show="!showMenu"></i>
                        <i class="fa-solid fa-xmark" v-show="showMenu"></i>
                    </span>
                </div>
            </li>
            <li class="list" v-for="(item, index) in homeItems" :key="index"
                :class="{ 'active fa-bounce': index === activeIndex }" @click="setActive(index)">

                <router-link :to="item.Link">
                    <span class="icon">
                        <i class="fa-solid" :class="item.Logo"></i>
                    </span>
                    <span class="text">{{ item.Title }}</span>
                </router-link>

            </li>

            <div class="bottom">
                <!-- <li class="list">
                <a href="#">
                    <span class="icon">
                        <div class="imgBx">
                            <img src="img/testUser.jpg" alt="">
                        </div>
                    </span>
                    <span class="text">User Test</span>
                </a>
            </li> -->
                <li @click="changeLanguage('en')" v-if="currentLanguage != 'en'">
                    <router-link to="">
                        <span class="icon">
                            <i class="fa-solid fa-language"></i>
                        </span>
                        <span class="text">English</span>
                    </router-link>
                </li>
                <li @click="changeLanguage('zh')" v-if="currentLanguage != 'zh'">
                    <router-link to="">
                        <span class="icon">
                            <i class="fa-solid fa-language"></i>
                        </span>
                        <span class="text">中文</span>
                    </router-link>
                </li>
                <li @click="Logout">
                    <router-link to="">
                        <span class="icon">
                            <i class="fa-solid fa-right-from-bracket"></i>
                        </span>
                        <span class="text">Logout</span>
                    </router-link>
                </li>
            </div>
        </ul>
    </div>
</template>
  
<script>
// import axios from 'axios';

export default {

    data() {
        return {
            homeItems: [
                { Title: this.$t("Home"), Link: '/', Logo: 'fa-home' },
                { Title: this.$t("Contact"), Link: '/Contact', Logo: 'fa-address-book' },
                { Title: this.$t("Message"), Link: '/Message', Logo: 'fa-message' },
                { Title: this.$t("ChangePassword"), Link: '/CP', Logo: 'fa-pen' },
            ],
            activeIndex: 0,
            showMenu: false,
        };
    },
    computed: {
        currentLanguage() {
            // 使用 computed 属性获取当前语言
            return this.$i18n.locale;
        },
    },

    mounted() {
        this.checkLanguage();
        // 在组件挂载后，发送 GET 请求获取数据
        // axios.get('https://bo.app96.cc/VueAPI/Navbar.php?AppID=' + localStorage.getItem('AppID'))
        //     .then(response => {
        //         this.homeItems = response.data;
        //         // 获取当前页面的路径
        //         var currentPath = window.location.pathname;
        //         // 遍历 homeItems 查找与当前页面路径匹配的对象的 Index 属性
        //         for (var i = 0; i < this.homeItems.length; i++) {
        //             if (JSON.stringify(this.homeItems[i].Link) == "\"" + currentPath + "\"") {
        //                 this.activeIndex = i;
        //                 break;
        //             }
        //         }

        //     })
        //     .catch(error => {
        //         console.error('Error fetching data: ', error);
        //     });
    },
    methods: {
        checkLanguage() {
            // 在需要检查语言的地方调用 checkLanguage 方法
            const currentLanguage = this.$i18n.locale;
            console.log('Current Language:', currentLanguage);
        },
        changeLanguage(locale) {
            this.$store.dispatch('changeLanguage', locale);
        },
        toggleMenu() {
            if (window.innerWidth < "600") {
                this.showMenu = !this.showMenu;
                if (this.showMenu) {
                    document.getElementById("sidebar").style.width = "100%";
                    document.getElementById("sidebar").style.height = "100%";
                } else {
                    document.getElementById("sidebar").style.width = "100%";
                    document.getElementById("sidebar").style.height = "60px";
                }
            }

        },
        setActive(index) {
            this.activeIndex = index;

            this.toggleMenu();
        },
        Logout() {
            localStorage.removeItem('AppID');
            window.location.reload();
        }
    },
};
</script>
  