<template>
    <div class="container" style="margin-top:10px;">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center nav-tabs">
            <div class="nav" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history"
                    type="button" role="tab" aria-controls="nav-history" aria-selected="true">{{ $t('Record') }}</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{{ $t('Timing') }}</button>
            </div>
            <div class="">
                <!-- 按钮触发 Modal -->
                <i class="fa-solid fa-circle-plus fa-lg" style="color: #40a04b;" role="button" data-bs-toggle="modal"
                    data-bs-target="#myModal"></i>
                <!-- Bootstrap Modal -->
                <div class="modal fade" id="myModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">{{ $t('SendMessage') }}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <!-- Modal 内容在这里加载 MessageSend 组件 -->
                            <div class="modal-body">
                                <MessageSend />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab"
                tabindex="0">
                <MessageHistory />

            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                <MessageTime />
            </div>
        </div>
    </div>
</template>
<script>
import MessageSend from "@/views/MessageSend.vue";
import MessageHistory from "@/views/MessageHistory.vue";
import MessageTime from "@/views/MessageTime.vue";

export default {
    components: {
        MessageSend,
        MessageHistory,
        MessageTime
    },
};
</script>