import { createApp, provide } from 'vue'
import MainApp from './App.vue'
import router from './router';
import i18n from './i18n';
import store from './store';
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


const app = createApp(MainApp);

app.use(VXETable);


const eventBus = {
    // 定义你的事件和方法
    emitEvent(event, data) {
        app.config.globalProperties.$emit(event, data);
    },
    onEvent(event, callback) {
        app.config.globalProperties.$on(event, callback);
    },
};
provide('eventBus', eventBus);


app.use(store);
app.use(i18n);
app.use(router);
app.mount('#app');
