<template>
    <div class="container" style="margin-top:10px;">
        Welcome 
        <div id="chart">
        </div>
    </div>
</template>
<script>
// import axios from 'axios';
// import ApexCharts from "apexcharts";

export default {

    data() {
        return {
            Items: [],
        };
    },
    created() {
        // axios.get('https://bo.app96.cc/VueAPI/MessageSendState.php?AppID=' + localStorage.getItem('AppID'))
        //     .then(response => {
        //         this.Items = response.data;
        //         this.initChart();
        //     })
        //     .catch(error => {
        //         console.error('Error fetching data: ', error);
        //     });
    },

    methods: {

        // initChart() {
        //     var options = {
        //         series: [{
        //             name: "發送率",
        //             data: this.Items
        //         },],
        //         chart: {
        //             height: 350,
        //             type: 'line',
        //             dropShadow: {
        //                 enabled: true,
        //                 color: '#000',
        //                 top: 18,
        //                 left: 7,
        //                 blur: 10,
        //                 opacity: 0.2
        //             },
        //             toolbar: {
        //                 show: false
        //             }
        //         },
        //         colors: ['#77B6EA', '#545454'],
        //         dataLabels: {
        //             enabled: true,
        //         },
        //         stroke: {
        //             curve: 'smooth'
        //         },
        //         title: {
        //             text: this.$t("SendMessage"),
        //             align: 'center'
        //         },
        //         grid: {
        //             borderColor: '#e7e7e7',
        //             row: {
        //                 colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //                 opacity: 0.5
        //             },
        //         },
        //         markers: {
        //             size: 1
        //         },
        //         xaxis: {
        //             // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        //             title: {
        //                 text: ''
        //             }
        //         },
        //         yaxis: {
        //             title: {
        //                 text: 'Temperature'
        //             },
        //             min: 0,
        //             max: 10
        //         },
        //         legend: {
        //             position: 'top',
        //             horizontalAlign: 'right',
        //             floating: true,
        //             offsetY: -25,
        //             offsetX: -5
        //         }
        //     };
        //     var chart = new ApexCharts(document.querySelector("#chart"), options);
        //     chart.render();
        // }
    }
};
</script>